import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CSelect from '@components/UI/select/CSelect';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Divider,
  Space,
  Table,
  Tag,
  Button,
  InputNumber,
} from 'antd';
import type { TableProps } from 'antd';
import { useEffect, useState } from 'react';
const { Option } = Select;
import DateMask from '@modules/Edo/DateMask';
import TextArea from 'antd/es/input/TextArea';

interface DataType {
  key: string;
  damage: string;
  damageclassisn: string;
  full_name: string;
  franchtype: string;
  franchsum: string;
  franchtariff: string;
  tags: string[];
}

function PrimaryConditionsItemDynamic({
  primaryCondForm,
  productSelectedQuotation,
  modalSetting,
  quotationData,
  selectedObj,
  risk,
  primaryItem,
  index,
  carForm,
  saveObjectCond,
  deletePrimaryCond,
  item,
  isDelete,
}) {
  const [editRisk, setEditRisk] = useState<any>();
  const [riskPackage, setRiskPackage] = useState<any>();
  const [subClassIsn, setSubClassIsn] = useState<any>();
  const [editForm] = Form.useForm();
  const [maxTariff, setMaxTariff] = useState<any>();
  const [minTariff, setMinTariff] = useState<any>();
  const [borderColor, setBorderColor] = useState<any>('#d9d9d9');

  const selectRisk = (isn) => {
    const data = productSelectedQuotation[0]?.risks.filter((item) => {
      if (
        Number(item.RiskISN) === Number(isn) &&
        (Number(item.SubClassObjISN) === Number(subClassIsn) ||
          item.SubClassObjISN.length === 0)
      ) {
        primaryCondForm.setFieldsValue({
          [index]: { ins_type_isn: item.InsTypeISN },
        });
        return item;
      }
    });
    const tariff = modalSetting.tariff.filter(
      (set) =>
        Number(set.risk_isn) === Number(data[0].RiskISN) &&
        set.curr_isn === 9813
    );
    setMaxTariff(Number(tariff[0].max_tariff));
    setMinTariff(Number(tariff[0].min_tariff));

    if (data[0].package_risks?.length > 0) {
      setRiskPackage(
        data[0].package_risks.map((riskItem) => {
          return {
            ...riskItem,
            franch_percent: '10',
            franchtariff: '10',
            damageclassisn: riskItem.loss_isn,
            damage: riskItem.loss_name,
            // franchtype: '-',
            isn: null,
          };
        })
      );
      risk.setAllRisk({
        [index]: data.map((riskItem) => {
          return {
            ...riskItem,
            franch_percent: '10',
            franchtariff: '10',
            damageclassisn: riskItem.loss_isn,
            damage: riskItem.loss_name,
            // franchtype: 'Б',
            isn: null,
          };
        }),
      });
    } else {
      setRiskPackage(null);
    }

    saveObjectCond(
      {
        [index]: data[0].package_risks.map((riskItem) => {
          return {
            ...riskItem,
            franchpercent: '10',
            franchtariff: '10',
            damageclassisn: riskItem.loss_isn,
            damage: riskItem.loss_name,
            remark: riskItem.franch_remark,
            // franchtype: 'Б',
            isn: null,
          };
        }),
      },
      risk.setRisk
    );
  };
  const deleteItemOfRisk = (id) => {
    if (risk.risk[index] && risk.risk[index].length > 1) {
      let data = risk.risk[index].map((item) => {
        if (Number(item.isn) === Number(id)) {
          return {
            ...item,
            operation: 'D',
          };
        } else {
          return item;
        }
      });
      // let pack = riskPackage.map((item) => {
      //   if (item.id === id) {
      //     return {
      //       ...item,
      //       operation: 'D',
      //     };
      //   } else {
      //     return item;
      //   }
      // });
      // setRiskPackage(pack);

      risk.setRisk({ ...risk, [index]: data });
    }
  };
  const updateItemOfRisk = (data) => {
    setEditRisk(data);
    editForm.setFieldsValue({
      id: Number(data.key),
      foreign_id: data.foreign_id,
      full_name: data.full_name,
      obj_isn: selectedObj.isn,
      franchtype: data.franchtype,
      franchtariff: data.franchtariff,
      remark: data.remark,
      damage: data.damage,
      index,
    });
  };
  const closeEdit = () => {
    setEditRisk(null);
    editForm.resetFields();
  };
  const saveEdit = () => {
    risk.setRisk({
      ...risk.risk,
      [index]: riskPackage.map((item) => {
        if (item.isn === editRisk.key) {
          return {
            ...item,
            damage: editForm.getFieldsValue().damage,
            franchsum: editForm.getFieldsValue().franchsum,
            full_name: editForm.getFieldsValue().full_name,
            franchtype: editForm.getFieldsValue().franchtype,
            franchtariff: editForm.getFieldsValue().franchtariff,
            remark: editForm.getFieldsValue().remark,
            franch_remark: editForm.getFieldsValue().remark,
          };
        } else {
          return item;
        }
      }),
    });
    setRiskPackage(
      riskPackage.map((item) => {
        if (item.isn === editRisk.key) {
          console.log(editForm.getFieldsValue());
          return {
            ...item,
            damage: editForm.getFieldsValue().damage,
            franchsum: editForm.getFieldsValue().franchsum,
            full_name: editForm.getFieldsValue().full_name,
            franchtype: editForm.getFieldsValue().franchtype,
            franchtariff: editForm.getFieldsValue().franchtariff,
            franch_remark: editForm.getFieldsValue().remark,
            remark: editForm.getFieldsValue().remark,
          };
        } else {
          return item;
        }
      })
    );
    setEditRisk(null);
    editForm.resetFields();
  };
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Риск',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '30%',
    },
    {
      title: 'Х-р ущерба',
      dataIndex: 'damageclassisn',
      key: 'damageclassisn',
      width: '10%',
      render: (_, record) => (
        <p style={{ marginBottom: '0' }}>{record.damage}</p>
      ),
    },
    {
      title: 'Лими ответ-и',
      dataIndex: 'franchtype',
      key: 'franchtype',
      width: '10%',
    },
    {
      title: 'Сумма франшизы',
      key: 'franchsum',
      dataIndex: 'franchsum',
      width: '10%',
    },
    {
      title: '% франшизы',
      key: 'franchtariff',
      dataIndex: 'franchtariff',
      width: '10%',
      render: (_, record) => (
        <p style={{ marginBottom: '0' }}>{record.franchtariff}%</p>
      ),
    },
    {
      title: 'Примечание',
      key: 'remark',
      dataIndex: 'remark',
      width: '14%',
    },
    {
      title: '',
      key: 'action',
      width: '18%',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => updateItemOfRisk(record)}>Изменить</a>
            <a onClick={() => deleteItemOfRisk(record.key)}>Удалить</a>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    if (selectedObj) {
      primaryCondForm.setFieldsValue({
        [index]: {
          obj_isn: selectedObj.isn,
          fid_obj: selectedObj.fid_obj,
          fid: item.fid,
          index,
        },
      });
    }
  }, [selectedObj]);
  useEffect(() => {
    if (carForm) {
      setSubClassIsn(carForm.getFieldsValue().sub_class_isn);
    }
  }, [carForm]);

  const check = (key, index) => {
    if (primaryCondForm.getFieldsValue()[index][key]?.length > 0) {
      if (
        primaryCondForm.getFieldsValue()[index].insurer_sum &&
        primaryCondForm.getFieldsValue()[index].tariff
      ) {
        primaryCondForm.setFieldsValue({
          [index]: {
            premium_sum:
              (primaryCondForm.getFieldsValue()[index].insurer_sum *
                primaryCondForm.getFieldsValue()[index].tariff) /
              100,
          },
        });
      } else if (
        primaryCondForm.getFieldsValue()[index].insurer_sum &&
        primaryCondForm.getFieldsValue()[index].premium_sum
      ) {
        primaryCondForm.setFieldsValue({
          [index]: {
            tariff:
              (primaryCondForm.getFieldsValue()[index].premium_sum /
                primaryCondForm.getFieldsValue()[index].insurer_sum) *
              100,
          },
        });
      } else if (
        primaryCondForm.getFieldsValue()[index].tariff &&
        primaryCondForm.getFieldsValue()[index].premium_sum
      ) {
        primaryCondForm.setFieldsValue({
          [index]: {
            insurer_sum:
              (primaryCondForm.getFieldsValue()[index].premium_sum /
                primaryCondForm.getFieldsValue()[index].tariff) *
              100,
          },
        });
      }
      if (
        primaryCondForm.getFieldsValue()[index].premium_sum &&
        primaryCondForm.getFieldsValue()[index].discount
      ) {
        primaryCondForm.setFieldsValue({
          [index]: {
            actual_sum: (primaryCondForm.getFieldsValue()[index].premium_sum +=
              (primaryCondForm.getFieldsValue()[index].premium_sum *
                primaryCondForm.getFieldsValue()[index].discount) /
              100),
          },
        });
      } else if (
        primaryCondForm.getFieldsValue()[index].premium_sum &&
        primaryCondForm.getFieldsValue()[index].actual_sum
      ) {
        primaryCondForm.setFieldsValue({
          [index]: {
            discount:
              ((primaryCondForm.getFieldsValue()[index].actual_sum -
                primaryCondForm.getFieldsValue()[index].premium_sum) /
                primaryCondForm.getFieldsValue()[index].premium_sum) *
              100,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (risk.risk) {
      console.log(risk);

      setRiskPackage(risk.risk[index]);
    }
  }, [risk.risk]);
  useEffect(() => {
    if (item) {
      primaryCondForm.setFieldsValue({
        [index]: {
          fid: item.fid,
          cond_isn: item.isn,
          obj_isn: item.objisn,
          ins_type_isn: item.insclassisn,
          operation: item.operation,
        },
      });
      let tariff = modalSetting.tariff.filter(
        (set) =>
          Number(item.riskisn) === Number(set.risk_isn) && set.curr_isn === 9813
      );
      if (tariff[0]) {
        setMaxTariff(Number(tariff[0].max_tariff));
        setMinTariff(Number(tariff[0].min_tariff));
      }

      setRiskPackage(
        item.agrrisk?.map((riskItem) => {
          return {
            ...riskItem,
            risk_isn: riskItem.riskisn,
            isn: riskItem.isn,
            foreign_id: riskItem.isn,
            risk_name: riskItem.riskisn_name,
            franch_percent: riskItem.franchtariff,
            loss_name: riskItem.damageclassisn_name,
            remark: riskItem.remark,
            franch_remark: riskItem.remark,
          };
        })
      );
    }
  }, [item]);
  DateMask();

  return (
    <>
      <Col span={24}>
        <Form.Item name={[index, 'obj_isn']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'operation']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'fid_obj']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'fid']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'ins_type_isn']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'index']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item name={[index, 'cond_isn']} hidden noStyle>
          <Input />
        </Form.Item>
        <Form.Item
          name={[index, 'risk_isn']}
          label="Риски (пакет рисков)"
          style={{ width: '100%' }}
          hidden={item.operation === 'D'}
        >
          <CSelect
            placeholder="Риски (пакет рисков)"
            style={{ width: '100%' }}
            onSelect={selectRisk}
          >
            {productSelectedQuotation[0]?.risks
              ?.filter(
                (elem) =>
                  Number(elem.SubClassObjISN) === Number(subClassIsn) ||
                  elem.SubClassObjISN.length === 0
              )
              .map((item) => {
                return (
                  <Option
                    value={Number(item.RiskISN)}
                    key={Number(item.RiskISN)}
                  >
                    {item.RiskName}
                  </Option>
                );
              })}
          </CSelect>
        </Form.Item>
      </Col>
      {Object.keys(modalSetting.primary_cond).map((key) => {
        if (key === 'franche') {
          return Object.keys(modalSetting.primary_cond[key]).map(
            (franch_key) => {
              switch (franch_key) {
                case 'franch_sum':
                  return (
                    <Col span="8" key={`${franch_key}-index`}>
                      <Form.Item
                        label={
                          modalSetting.primary_cond[key][franch_key][0].name
                        }
                        name={[index, franch_key]}
                        hidden={item.operation === 'D'}
                      >
                        <Input
                          type="number"
                          placeholder={
                            modalSetting.primary_cond[key][franch_key][0].name
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                case 'franch_tariff':
                  return (
                    <Col span="8" key={`${franch_key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key][franch_key].name}
                        name={[index, franch_key]}
                        hidden={item.operation === 'D'}
                      >
                        <InputNumber
                          // type="number"
                          placeholder={
                            modalSetting.primary_cond[key][franch_key].name
                          }
                          style={{
                            width: '100%',
                          }}
                        />
                      </Form.Item>
                    </Col>
                  );
                case 'franch_type':
                  return (
                    <Col span="8" key={`${franch_key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key][franch_key].name}
                        name={[index, franch_key]}
                        hidden={item.operation === 'D'}
                      >
                        <Select
                          placeholder={
                            modalSetting.primary_cond[key][franch_key].name
                          }
                        >
                          {modalSetting.primary_cond[key][
                            franch_key
                          ].dictis.map((dicti) => {
                            return (
                              <Option key={dicti} value={dicti}>
                                {dicti}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
              }
            }
          );
        } else {
          if (modalSetting.primary_cond[key]) {
            switch (modalSetting.primary_cond[key].type) {
              case 'date':
                return (
                  <Col span="8" key={`${key}-index`}>
                    <Form.Item
                      label={modalSetting.primary_cond[key].name}
                      name={[index, key]}
                      hidden={item.operation === 'D'}
                    >
                      <DatePicker
                        className="dateMask"
                        size="middle"
                        style={{ width: '100%' }}
                        format="DD.MM.YYYY"
                      />
                    </Form.Item>
                  </Col>
                );
              case 'int':
                if (
                  key === 'tariff' ||
                  key === 'premium_sum' ||
                  key === 'discount' ||
                  key === 'actual_sum'
                ) {
                  if (key === 'tariff') {
                    console.log(maxTariff);
                    console.log(minTariff);

                    return (
                      <Col span="8" key={`${key}-index`}>
                        <Form.Item
                          label={modalSetting.primary_cond[key].name}
                          name={[index, key]}
                          hidden={item.operation === 'D'}
                        >
                          <InputNumber
                            onBlur={() => check(key, index)}
                            // type="number"
                            style={{ width: '100%' }}
                            placeholder={modalSetting.primary_cond[key].name}
                            max={maxTariff}
                            min={minTariff}
                          />
                        </Form.Item>
                      </Col>
                    );
                  } else {
                    return (
                      <Col span="8" key={`${key}-index`}>
                        <Form.Item
                          label={modalSetting.primary_cond[key].name}
                          name={[index, key]}
                          hidden={item.operation === 'D'}
                        >
                          <Input
                            onBlur={() => check(key, index)}
                            type="number"
                            placeholder={modalSetting.primary_cond[key].name}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                } else {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Input
                          type="number"
                          placeholder={modalSetting.primary_cond[key].name}
                        />
                      </Form.Item>
                    </Col>
                  );
                }

              case 'string':
                if (key === 'insurer_sum') {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Input
                          type="number"
                          onBlur={() => check(key, index)}
                          placeholder={modalSetting.primary_cond[key].name}
                        />
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Input
                          placeholder={modalSetting.primary_cond[key].name}
                        />
                      </Form.Item>
                    </Col>
                  );
                }

              case 'dicti':
                if (
                  modalSetting.primary_cond[key].name ===
                  'Страховая сумма/ лимит ответственности'
                ) {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Select
                          placeholder={modalSetting.primary_cond[key].name}
                        >
                          {modalSetting.primary_cond[key].dictis.map(
                            (dicti) => (
                              <Option
                                key={dicti.risk_isn}
                                value={dicti.risk_isn}
                              >
                                {dicti.value}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else if (modalSetting.primary_cond[key].name === 'Валюта') {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Select
                          placeholder={modalSetting.primary_cond[key].name}
                        >
                          {modalSetting.primary_cond[key].dictis.map(
                            (dicti) => (
                              <Option
                                key={dicti.curr_isn}
                                value={dicti.curr_isn}
                              >
                                {dicti.curr_name}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return (
                    <Col span="8" key={`${key}-index`}>
                      <Form.Item
                        label={modalSetting.primary_cond[key].name}
                        name={[index, key]}
                        hidden={item.operation === 'D'}
                      >
                        <Select
                          placeholder={modalSetting.primary_cond[key].name}
                        >
                          {modalSetting.primary_cond[key].dictis.map(
                            (dicti) => (
                              <Option
                                key={dicti.curr_isn}
                                value={dicti.curr_isn}
                              >
                                {dicti.curr_name}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                }
            }
          }
        }
      })}
      <Col span="24" key={`remark-index`}>
        <Form.Item
          label="Примечание франшизы"
          name={[index, 'remark']}
          hidden={item.operation === 'D'}
        >
          <TextArea
            rows={4}
            onBlur={() => check('remark-index', index)}
            placeholder={'Примечание франшизы'}
          />
        </Form.Item>
      </Col>

      {riskPackage && item.operation !== 'D' && (
        <Table
          className="mt-5"
          columns={columns}
          dataSource={riskPackage
            ?.filter((item) => {
              if (!item.operation) {
                if (!editRisk || editRisk.key !== item.isn) {
                  return { ...item, id: Number(item.risk_isn) };
                }
              }
            })
            .map((item, index) => {
              return {
                full_name: item.risk_name,
                damage: item.loss_name,
                liabilityLimit: item.liabilityLimit,
                franchsum: item.franchsum,
                num_code: item.franch_percent,
                remark: item.franch_remark,
                franchtariff: item.franchtariff,
                franchtype: item.franchtype,
                key: item.foreign_id,
                id: item.risk_isn,
              };
            })}
          showHeader={true}
          pagination={false}
          // pagination={{
          //   position: [
          //     'none' as TablePaginationPosition,
          //     'none' as TablePaginationPosition,
          //   ],
          // }}
        />
      )}
      {riskPackage?.map((item, index) => {
        if (editRisk && editRisk.key === item.isn) {
          return (
            <Form name="editForm" className="mt-2" form={editForm}>
              <Row style={{ width: '100%' }}>
                <Form.Item name="foreign_id" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="full_name" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="riskisn" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="obj_isn" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="index" hidden noStyle>
                  <Input />
                </Form.Item>
                <Col style={{ width: '28%', padding: '0 8px' }}>
                  <Form.Item name="id" hidden={item.operation === 'D'}>
                    <Select
                      placeholder="Риск"
                      style={{ width: '100%' }}
                      // disabled
                    >
                      {riskPackage?.map((item) => {
                        return (
                          <Option
                            value={Number(item.isn)}
                            key={Number(item.isn)}
                          >
                            {item.riskisn_name
                              ? item.riskisn_name
                              : item.risk_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: '10%', padding: '0 8px' }}>
                  <Form.Item name="damage" hidden={item.operation === 'D'}>
                    <Input placeholder="Х-р ущерба" />
                  </Form.Item>
                </Col>
                <Col style={{ width: '10%', padding: '0 8px' }}>
                  <Form.Item name="franchtype" hidden={item.operation === 'D'}>
                    <Input placeholder="Лимит ответ-и" />
                  </Form.Item>
                </Col>
                <Col style={{ width: '10%', padding: '0 8px' }}>
                  <Form.Item name="franchsum" hidden={item.operation === 'D'}>
                    <Input placeholder="Сумма франшизы" />
                  </Form.Item>
                </Col>
                <Col style={{ width: '10%', padding: '0 8px' }}>
                  <Form.Item
                    name="franchtariff"
                    hidden={item.operation === 'D'}
                  >
                    <Input placeholder="% франшизы" />
                  </Form.Item>
                </Col>
                <Col style={{ width: '14%', padding: '0 8px' }}>
                  <Form.Item name="remark" hidden={item.operation === 'D'}>
                    <Input placeholder="Примечание" />
                  </Form.Item>
                </Col>
                <Row gutter={6} style={{ width: '18%', padding: '0 20px' }}>
                  <Button onClick={saveEdit}>
                    <CheckOutlined />
                  </Button>
                  <Col>
                    <Button onClick={closeEdit}>x</Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          );
        }
      })}
      {isDelete && item.operation !== 'D' && (
        <Col span={24} className="mt-3 mb-3">
          <Button
            type="primary"
            danger
            style={{ width: '100%' }}
            onClick={() => deletePrimaryCond(item)}
          >
            Удалить условие
          </Button>
        </Col>
      )}
      {item.operation !== 'D' && <Divider />}
    </>
  );
}
export default PrimaryConditionsItemDynamic;
